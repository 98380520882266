header {
  width: 100%;
  top: 0;
  height: 5rem;
  z-index: 999;
  font-size: 1.75rem;
  position: fixed;
  background-color: black;
  box-shadow: 5px 5px 10px rgba(#000000, 0.5);

  .header__line {
    height: 0.5rem;
    width: 100%;
    background-color: #2acdff;
    font-size: 10px;
    padding-right: 5rem;
    text-align: right;
    // color: white;
    user-select: none;
    cursor: default;
  }

  main {
    padding: 0 1.5rem;
    padding-top: 0.5rem;
    display: flex;
    width: 100%;
    height: 4.5rem;
    justify-content: space-between;
    align-items: center;
  }

  &.scrolled {
    main {
      box-shadow: 2px 2px 5px rgba(#fff, 0.2);
    }

    .header__line {
      background-color: white;
      color: white;
    }

    background-color: white;

    * {
      color: black;
    }

  }
}


@media screen and (min-width: 728px) {
  header {
    height: 7rem;

    .header__line {
      height: 0.8rem;
    }

    main {
      padding: 0 2rem;
      height: 6rem;
    }

  }
}



@media screen and (min-width: 1200px) {
  header {
    height: 7rem;

    .header__line {
      height: 0.8rem;
    }

    main {
      padding: 0 5rem;
      height: 6rem;
    }

  }
}